




































































































// Vue
import Vue from "vue";
// Vuelidate
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
// Models
import { IContact } from "@/core/_models/contact.model";
import ConfirmationDialog from "@/core/components/content/_dialogs/ConfirmationDialog.vue";

export default Vue.extend({
  name: "UpdateContactDialog",
  mixins: [validationMixin],
  components: {
    ConfirmationDialog
  },
  props: ["contact"],

  data() {
    return {
      dialog: false,
      isValid: false,

      name: this.contact.Name,
      email: this.contact.Email,
      phone: this.contact.Phone,
      extension: this.contact.Ext
    };
  },

  validations: {
    name: { required },
    email: { email }
  },

  methods: {
    /**
     * Submits the New Job to the server and updates the list.
     * @author Nick Brahimir
     */
    submit(): void {
      const updatedContact: IContact = {
        DatabaseId: this.contact.DatabaseId,
        Name: this.name,
        Email: this.email,
        Phone: this.phone,
        Ext: this.extension
      };

      this.$emit("updated-contact", updatedContact);
      this.dialog = false;
    },
    deleteContact(): void {
      const deletedContact: IContact = {
        DatabaseId: this.contact.DatabaseId,
        Name: this.name,
        Email: this.email,
        Phone: this.phone,
        Ext: this.extension
      };
      this.$emit("deleted-contact", deletedContact);
      this.dialog = false;
    },

    /**
     * Handles closing the Dialog.
     * @author Nick Brahimir
     */
    closeForm() {
      this.name = this.contact.Name;
      this.email = this.contact.Email;
      this.phone = this.contact.Phone;
      this.extension = this.contact.Ext;
      this.dialog = false;
    },

    /**
     * Clears the form.
     * @author Nick Brahimir
     */
    clearForm(): void {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.phone = "";
      this.extension = "";
    }
  },

  computed: {
    /**
     * Parses any Name errors.
     * @author Nick Brahimir
     */
    nameErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required");
      return errors;
    },

    /**
     * Parses any Email errors.
     * @author Nick Brahimir
     */
    emailErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.email.email && errors.push("Please provide a valid email address");
      return errors;
    }
  }
});
