



















































































































































































































































































































































// Vue
import Vue from "vue";
// Vuex
import { mapActions, mapGetters } from "vuex";
// Models
import { IJobSite, JobSite } from "@/core/_models/jobsite.model";
import { IContact } from "@/core/_models/contact.model";
import { ICustomer } from "@/core/_models/customer.model";
// Vuelidate
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// Components
import ConfirmationDialog from "@/core/components/content/_dialogs/ConfirmationDialog.vue";
import CreateCustomerDialog from "@/views/dispatch/customers/_dialogs/customer/CreateCustomerDialog.vue";
import CreateContactDialog from "@/views/dispatch/customers/_dialogs/contact/CreateContactDialog.vue";
import UpdateContactDialog from "@/views/dispatch/customers/_dialogs/contact/UpdateContactDialog.vue";
import CreateJobsiteDialog from "@/views/dispatch/customers/_dialogs/job-site/CreateJobsiteDialog.vue";
import UpdateJobsiteDialog from "@/views/dispatch/customers/_dialogs/job-site/UpdateJobsiteDialog.vue";
// Firebase
import "firebase/auth";
import "firebase/firestore";
import AdminService from "@/views/admin/Admin.service";

export default Vue.extend({
  name: "Customers",
  components: {
    ConfirmationDialog,
    CreateCustomerDialog,
    CreateContactDialog,
    UpdateContactDialog,
    CreateJobsiteDialog,
    UpdateJobsiteDialog
  },

  data() {
    return {
      isLoading: false,
      isContactsLoading: false,
      isJobsitesLoading: false,

      search: "",
      isCustomerSelected: false,
      isEditingCustomer: false,

      selectedCustomer: {} as ICustomer,
      businessAddress: "",
      databaseId: "",
      email: "",
      ext: undefined as number | undefined | null,
      isCOD: false,
      name: "",
      phone: "",
      notes: "",

      // Customer data
      customersData: [] as ICustomer[],
      selectedCustomerContactsData: [] as IContact[],
      selectedCustomerJobsitesData: [] as IJobSite[],

      contacts: [] as IContact[],
      jobsites: [] as IJobSite[]
    };
  },

  mounted() {
    this.getAllCustomers();
    // this.fetchCustomers();
  },

  mixins: [validationMixin],

  validations: {
    name: { required }
  },

  methods: {
    ...mapActions("customers", {
      fetchCustomers: "fetchCustomers",
      pushCustomers: "pushCustomers",
      setCustomer: "setCustomer",
      removeCustomer: "removeCustomer"
    }),

    ...mapActions("appMessages", {
      ADD_ERROR_MESSAGE: "ADD_ERROR_MESSAGE",
      ADD_SUCCESS_MESSAGE: "ADD_SUCCESS_MESSAGE"
    }),

    getAllCustomers(): void {
      this.customersData = [] as ICustomer[];
      this.isLoading = true;

      AdminService.getAllCustomers(this.currentUser.clientId).then((data: ICustomer[]) => {
        if (!data) return;
        this.customersData = data;
        this.isLoading = false;
      });
    },

    getAllCustomerContacts(databaseId: string): void {
      this.isContactsLoading = true;
      AdminService.getAllCustomerContacts(this.currentUser.clientId, databaseId).then(
        (data: IContact[]) => {
          if (!data) return;
          this.selectedCustomerContactsData = data;
          this.isContactsLoading = false;
        }
      );
    },

    getAllCustomerJobsites(databaseId: string): void {
      this.isJobsitesLoading = true;

      AdminService.getAllCustomerJobsites(this.currentUser.clientId, databaseId).then(
        (data: IContact[]) => {
          if (!data) return;
          this.selectedCustomerJobsitesData = data;
          this.isJobsitesLoading = false;
        }
      );
    },

    createCustomer(newCustomer: ICustomer): void {
      this.setCustomer(newCustomer)
        .then(() => {
          this.getAllCustomers();
          this.ADD_SUCCESS_MESSAGE(`Customer successfully created.`);
        })
        .catch((error) => {
          console.log(error);
          this.ADD_ERROR_MESSAGE(`Error creating customer. Please try again.`);
        });
    },

    deleteCustomer(deletedCustomer: ICustomer): void {
      AdminService.deleteCustomer(this.currentUser.clientId, deletedCustomer.DatabaseId!)
        .then((response: any) => {
          if (!response) return;
          this.getAllCustomers();
          this.ADD_SUCCESS_MESSAGE(`Customer successfully deleted.`);
          this.isCustomerSelected = false;
          this.isEditingCustomer = false;
        })
        .catch((error) => {
          console.log(error);
          this.ADD_ERROR_MESSAGE(`Error deleting customer. Please try again.`);
        });
    },

    updateCustomer(): void {
      const payload: ICustomer = {
        DatabaseId: this.databaseId,
        Email: this.email ?? null,
        BusinessAddress: this.businessAddress ?? null,
        Ext: this.ext,
        IsCOD: this.isCOD,
        LastUsedTimestamp: new Date(),
        Name: this.name,
        Notes: this.notes ?? null,
        Phone: this.phone ?? null
      };

      AdminService.updateCustomer(this.currentUser.clientId, payload)
        .then((response: any) => {
          if (!response) return;
          this.getAllCustomers();
          this.ADD_SUCCESS_MESSAGE(`Customer successfully updated.`);
          this.isEditingCustomer = false;
        })
        .catch((error) => {
          console.log(error);
          this.ADD_ERROR_MESSAGE(`Error updating customer. Please try again.`);
        });
    },

    setSelectedCustomer(selectedCustomer: ICustomer): void {
      if (this.selectedCustomer === selectedCustomer) {
        // Customer is already selected, deselect
        this.selectedCustomer = {} as ICustomer;
        this.isCustomerSelected = false;
        return;
      }

      this.selectedCustomer = selectedCustomer;
      this.isCustomerSelected = true;
      this.isEditingCustomer = false;
      this.businessAddress = selectedCustomer.BusinessAddress!;
      this.databaseId = selectedCustomer.DatabaseId!;
      this.email = selectedCustomer.Email!;
      this.isCOD = selectedCustomer.IsCOD!;
      this.name = selectedCustomer.Name;
      this.phone = selectedCustomer.Phone!;
      this.ext = selectedCustomer.Ext;
      this.notes = selectedCustomer.Notes!;

      this.getAllCustomerContacts(selectedCustomer.DatabaseId!);
      this.getAllCustomerJobsites(selectedCustomer.DatabaseId!);
    },

    cancelSelectedCustomer(): void {
      this.isCustomerSelected = true;
      this.businessAddress = this.selectedCustomer.BusinessAddress!;
      this.databaseId = this.selectedCustomer.DatabaseId!;
      this.email = this.selectedCustomer.Email!;
      this.isCOD = this.selectedCustomer.IsCOD!;
      this.name = this.selectedCustomer.Name;
      this.phone = this.selectedCustomer.Phone!;
      this.ext = this.selectedCustomer.Ext;
      this.notes = this.selectedCustomer.Notes!;
      this.isEditingCustomer = false;
    },

    createContact(payload: IContact): void {
      AdminService.createCustomerContact(
        this.currentUser.clientId,
        this.selectedCustomer.DatabaseId!,
        payload
      )
        .then((response: any) => {
          if (!response) return;
          this.getAllCustomerContacts(this.selectedCustomer.DatabaseId!);
          this.ADD_SUCCESS_MESSAGE(`Contact successfully created.`);
        })
        .catch((error) => {
          console.log(error);
          this.ADD_ERROR_MESSAGE(`Error creating contact. Please try again.`);
        });
    },

    updateContact(payload: IContact): void {
      AdminService.updateCustomerContact(
        this.currentUser.clientId,
        this.selectedCustomer.DatabaseId!,
        payload
      )
        .then((response: any) => {
          if (!response) return;
          this.getAllCustomerContacts(this.selectedCustomer.DatabaseId!);
          this.ADD_SUCCESS_MESSAGE(`Contact successfully updated.`);
        })
        .catch((error) => {
          console.log(error);
          this.ADD_ERROR_MESSAGE(`Error updating contact. Please try again.`);
        });
    },

    deleteContact(payload: IContact): void {
      AdminService.deleteCustomerContact(
        this.currentUser.clientId,
        this.selectedCustomer.DatabaseId!,
        payload.DatabaseId!
      )
        .then((response) => {
          if (!response) return;
          this.getAllCustomerContacts(this.selectedCustomer.DatabaseId!);
          this.ADD_SUCCESS_MESSAGE(`Contact successfully deleted.`);
        })
        .catch((error) => {
          console.log(error);
          this.ADD_ERROR_MESSAGE(`Error deleting contact. Please try again.`);
        });
    },

    createJobSite(payload: JobSite): void {
      AdminService.createCustomerJobSite(
        this.currentUser.clientId,
        this.selectedCustomer.DatabaseId!,
        payload
      )
        .then((response: any) => {
          if (!response) return;
          this.getAllCustomerJobsites(this.selectedCustomer.DatabaseId!);
          this.ADD_SUCCESS_MESSAGE(`Job site successfully created.`);
        })
        .catch((error) => {
          console.log(error);
          this.ADD_ERROR_MESSAGE(`Error creating job site. Please try again.`);
        });
    },

    updateJobSite(payload: JobSite): void {
      AdminService.updateCustomerJobSite(
        this.currentUser.clientId,
        this.selectedCustomer.DatabaseId!,
        payload
      )
        .then((response: any) => {
          if (!response) return;
          this.getAllCustomerJobsites(this.selectedCustomer.DatabaseId!);
          this.ADD_SUCCESS_MESSAGE(`Job site successfully updated.`);
        })
        .catch((error) => {
          console.log(error);
          this.ADD_ERROR_MESSAGE(`Error updating job site. Please try again.`);
        });
    },

    deleteJobSite(payload: JobSite): void {
      AdminService.deleteCustomerJobSite(
        this.currentUser.clientId,
        this.selectedCustomer.DatabaseId!,
        payload.DatabaseId!
      )
        .then((response) => {
          if (!response) return;
          this.getAllCustomerJobsites(this.selectedCustomer.DatabaseId!);
          this.ADD_SUCCESS_MESSAGE(`Job site successfully deleted.`);
        })
        .catch((error) => {
          console.log(error);
          this.ADD_ERROR_MESSAGE(`Error deleting job site. Please try again.`);
        });
    }
  },

  computed: {
    ...mapGetters({
      data: "customers/items",
      currentUser: "currentUser"
    }),
    /**
     * Filters Customers via search bar
     * @author Nick Brahimir
     */
    filteredCustomers(): any {
      if (!this.search) return this.customersData;

      return this.customersData.filter((element: ICustomer) => {
        return element.Name?.toLowerCase().includes(this.search?.toLowerCase());
      });
    },

    /**
     * Parses any Name errors.
     *
     * @author Nick Brahimir
     */
    nameErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) {
        return errors;
      }

      !this.$v.name.required && errors.push("Customer name is required");

      return errors;
    }
  }
});
