
















































































// Vue
import Vue, { PropType } from "vue";
// Vuelidate
import { validationMixin } from "vuelidate";
// Models
import { IJobSite } from "@/core/_models/jobsite.model";
import ConfirmationDialog from "@/core/components/content/_dialogs/ConfirmationDialog.vue";

export default Vue.extend({
  name: "UpdateJobsiteDialog",
  mixins: [validationMixin],
  components: {
    ConfirmationDialog
  },
  props: {
    jobSite: {
      type: Object as PropType<IJobSite>,
      required: true
    }
  },

  data() {
    return {
      dialog: false,
      isValid: false,

      name: "",
      streetAddress: "",
      cityAddress: "",
      notes: ""
    };
  },

  methods: {
    initialize(): void {
      if (this.jobSite) {
        this.name = this.jobSite.Name ?? "";
        this.streetAddress = this.jobSite.StreetAddress ?? "";
        this.cityAddress = this.jobSite.CityAddress ?? "";
        this.notes = this.jobSite.Notes ?? "";
      }
    },

    checkValid(): boolean {
      if (this.name || this.streetAddress) return true;
      return false;
    },

    submit(): void {
      if (!this.checkValid()) return;

      // Default the Jobsite Name to it's StreetAddress if no Name is provided
      if (!this.name) this.name = this.streetAddress;

      const updatedJobSite: IJobSite = {
        DatabaseId: this.jobSite.DatabaseId,
        Name: this.name,
        StreetAddress: this.streetAddress,
        LocationType: "Address",
        CityAddress: this.cityAddress,
        Notes: this.notes
      };

      this.$emit("updated-jobsite", updatedJobSite);
      this.dialog = false;
    },

    deleteJobSite(): void {
      const deletedJobSite: IJobSite = {
        DatabaseId: this.jobSite.DatabaseId,
        Name: this.name,
        StreetAddress: this.streetAddress,
        LocationType: "Address",
        CityAddress: this.cityAddress,
        Notes: this.notes
      };

      this.$emit("deleted-jobsite", deletedJobSite);
      this.close();
    },

    close() {
      this.name = "";
      this.streetAddress = "";
      this.cityAddress = "";
      this.notes = "";

      this.dialog = false;
    }
  }
});
